.top-menu {
  position: fixed;
  left: 0;
  z-index: 1;
  top: 0;
  width: 100%;
  padding: 20px;
  display: flex;
  background: #fff;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  transition: all 0.3s ease-in-out;
}

@media (min-width: 1025px) {
  .top-menu {
    padding: 5.78125vw 5.20833vw;
  }
}

.top-menu__burger {
  box-sizing: content-box;
  width: 25px;
  height: 25px;
  padding: 15px 20px;
  position: absolute;
  top: -1px;
  right: 0;
  z-index: 3;
}

.top-menu__burger span {
  display: inline-block;
  height: 3px;
  width: 100%;
  background: #000;
  position: relative;
  transition: all 0.3s ease-in-out;
}

.top-menu__burger span:before,
.top-menu__burger span:after {
  width: 100%;
  height: 3px;
  content: '';
  position: absolute;
  background: #000;
  transition: all 0.3s ease-in-out;
}

.top-menu__burger span:before {
  top: -7px;
}

.top-menu__burger span:after {
  top: 7px;
}

@media (min-width: 1025px) {
  .top-menu__burger {
    display: none;
  }
}

.top-menu__burger.active span {
  background: #fff;
}

.top-menu__burger.active span:before {
  transform: rotate(45deg);
  top: 0;
}

.top-menu__burger.active span:after {
  transform: rotate(-45deg);
  top: 0;
}

.top-menu__logo {
  width: 100px;
  display: inline-block;
  position: relative;
  top: -1px;
}

.top-menu__logo__img {
  display: block;
  width: 100%;
}

.top-menu__logo__img--white {
  display: none;
}

@media (min-width: 1200px) {
  .top-menu__logo {
    width: 8.85417vw;
  }
}

.top-menu__closer {
  display: block;
  position: absolute;
}

@media (min-width: 960px) {
  .top-menu__closer {
    display: none;
  }
}

@media (min-width: 1025px) {
  .top-menu.scrolled {
    background: #fff;
    padding: 1.5625vw 5.20833vw;
    box-shadow: 0 0 0.52083vw rgba(0, 0, 0, 0.1);
  }
}

.top-menu__menu-list {
  list-style-type: none;
  padding-left: 0;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  position: absolute;
  left: 0;
  width: 100%;
  background: #fff;
  box-shadow: 1px 20px 20px rgba(0, 0, 0, 0.01);
  padding-bottom: 15px;
  top: -100vh;
  opacity: 0;
  transition: all 0.5s cubic-bezier(0.42, 0, 0.14, 1.23);
}

.top-menu__menu-list.active {
  top: 100%;
  opacity: 1;
}

@media (min-width: 1025px) {
  .top-menu__menu-list {
    background: transparent;
    top: 0;
    opacity: 1;
    width: auto;
    padding-bottom: 0;
    flex-direction: row;
    flex-wrap: nowrap;
    position: relative;
    box-shadow: none;
  }
}

.top-menu__menu-list__item {
  padding: 15px 30px;
}

@media (min-width: 1025px) {
  .top-menu__menu-list__item {
    padding: 0 1.82292vw;
    font-size: 0.72917vw;
    display: inline-block;
  }
}

.top-menu__menu-list__link {
  text-transform: uppercase;
  color: #000;
  font-family: 'Montserrat', sans-serif;
  text-decoration: none;
  display: block;
  font-size: 14px;
  position: relative;
  transition: all 0.3s ease-in-out;
}

@media (min-width: 1025px) {
  .top-menu__menu-list__link {
    font-size: 0.72917vw;
    display: inline-block;
  }
}

.top-menu__menu-list__link:after {
  position: absolute;
  transition: all 0.3s ease-in-out;
  width: 100%;
  content: '';
  height: 1px;
  top: 60%;
  left: 0;
  opacity: 0;
  background: #8aa63a;
}

.top-menu__menu-list__link:hover:after,
.top-menu__menu-list__link.active:after {
  opacity: 1;
  top: 150%;
}

.top-menu--white .top-menu__logo__img {
  display: block;
}

.top-menu--white .top-menu__logo__img--white {
  display: none;
}

@media (min-width: 1025px) {
  .top-menu--white .top-menu__logo__img {
    display: none;
  }

  .top-menu--white .top-menu__logo__img--white {
    display: block;
  }
}

@media (min-width: 1025px) {
  .top-menu--white {
    background: transparent;
  }

  .top-menu--white .top-menu__menu-list__link {
    color: #fff;
  }
}

.top-menu--white.scrolled .top-menu__logo__img {
  display: block;
}

.top-menu--white.scrolled .top-menu__logo__img--white {
  display: none;
}

@media (min-width: 1025px) {
  .top-menu--white.scrolled .top-menu__logo__img {
    display: block;
  }

  .top-menu--white.scrolled .top-menu__logo__img--white {
    display: none;
  }
}

.top-menu--white.scrolled .top-menu__menu-list__link {
  color: #000;
}

.top-menu--dark .top-menu__menu-list__link {
  color: #000;
}

