.btn {
  font-weight: 300;
  font-size: 13px;
  letter-spacing: 2px;
  color: #fff;
  display: inline-block;
  text-transform: uppercase;
  padding: 18px 84px 18px 44px;
  position: relative;
  border-left: 1px solid #8aa63a;
  border-top: 1px solid #8aa63a;
  border-bottom: 1px solid #8aa63a;
}

.btn:after {
  position: absolute;
  content: url(543b9af54818031284376bc319569ccb.svg);
  top: 50%;
  transform: translateY(-50%);
  margin-top: 3px;
  right: 33px;
  width: 16px;
  transition: all 0.3s ease-in-out;
}

@media (min-width: 1200px) {
  .btn:after {
    right: 1.71875vw;
    margin-top: 0.15625vw;
    width: 0.83333vw;
  }
}

.btn:before {
  right: 0;
  position: absolute;
  bottom: 0;
  height: 80%;
  width: 1px;
  content: '';
  background: #8aa63a;
  transition: all 0.3s ease-in-out;
}

.btn:hover:after {
  right: -6px;
}

@media (min-width: 1200px) {
  .btn:hover:after {
    right: -0.3125vw;
  }
}

.btn:hover:before {
  height: 0;
}

@media (min-width: 1200px) {
  .btn {
    padding: 0.9375vw 4.375vw 0.9375vw 2.29167vw;
    font-size: 0.78125vw;
    letter-spacing: 0.10417vw;
  }
}

.btn--left {
  padding: 18px 44px 18px 84px;
  border-right: 1px solid #8aa63a;
  border-top: 1px solid #8aa63a;
  border-bottom: 1px solid #8aa63a;
  border-left: none;
}

.btn--left:after {
  position: absolute;
  content: url(543b9af54818031284376bc319569ccb.svg);
  top: 50%;
  transform: translateY(-50%) rotate(180deg);
  left: 33px;
  right: auto;
  width: 16px;
  margin-top: -2px;
  transition: all 0.3s ease-in-out;
}

@media (min-width: 1200px) {
  .btn--left:after {
    margin-top: -0.10417vw;
    left: 1.71875vw;
    right: auto;
    width: 0.83333vw;
  }
}

.btn--left:before {
  left: 0;
  right: auto;
  position: absolute;
  bottom: 0;
  height: 80%;
  width: 1px;
  content: '';
  background: #8aa63a;
  transition: all 0.3s ease-in-out;
}

.btn--left:hover:after {
  left: -6px;
  right: auto;
}

@media (min-width: 1200px) {
  .btn--left:hover:after {
    left: -0.3125vw;
    right: auto;
  }
}

.btn--left:hover:before {
  height: 0;
}

@media (min-width: 1200px) {
  .btn--left {
    padding: 0.9375vw 2.29167vw 0.9375vw 4.375vw;
  }
}

.btn--dark {
  color: #000;
}

.pagination {
  text-align: center;
}

.pagination li {
  display: none;
}

.pagination li:first-of-type,
.pagination li:last-of-type {
  display: inline-block;
}

.pagination__ctrl {
  margin-bottom: 15px;
}

@media (min-width: 768px) {
  .pagination {
    text-align: right;
  }
}

.n-modal {
  background: #fff;
  opacity: 0;
  visibility: hidden;
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 5;
  padding: 30px 0;
  top: -120vh;
  overflow: auto;
  transition: all 0.3s ease-in-out;
}

.n-modal.active {
  top: 0;
  opacity: 1;
  visibility: visible;
}

@media (min-width: 768px) {
  .n-modal {
    padding: 50px 0;
  }
}

@media (min-width: 1200px) {
  .n-modal {
    padding: 5.20833vw;
  }
}

.n-modal__closer {
  width: 30px;
  height: 30px;
  position: fixed;
  top: 30px;
  right: 30px;
  z-index: 3;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}

.n-modal__closer:hover {
  transform: scale(1.2);
}

.n-modal__closer:before,
.n-modal__closer:after {
  position: absolute;
  left: 0;
  width: 40px;
  height: 2px;
  content: '';
  background: #8aa63a;
  transform-origin: 0 100%;
}

.n-modal__closer:before {
  transform: rotate(45deg);
  top: 0;
}

.n-modal__closer:after {
  transform: rotate(-45deg);
  bottom: 0;
  transform-origin: 0 0;
}

.lang-changer {
  font-family: 'Poppins', sans-serif;
  position: relative;
  top: 6px;
}

.lang-changer__lang {
  text-decoration: none;
  font-size: 18px;
  font-weight: 300;
  color: #000;
  margin-right: 13px;
  display: inline-block;
  text-transform: uppercase;
}

.lang-changer__lang:hover,
.lang-changer__lang.active {
  color: #858585;
}

.cms-content {
  font-size: 15px;
  line-height: 190%;
  font-weight: 300;
  color: #000;
  text-align: justify;
}

.cms-content--lighter {
  color: #5c5c5c;
}

@media (min-width: 1200px) {
  .cms-content {
    font-size: 1.04167vw;
  }
}

@media (min-width: 1600px) {
  .cms-content {
    font-size: 0.83333vw;
  }
}

.cms-content a {
  color: #000;
  transition: all 0.3s ease-in-out;
}

.cms-content a:hover {
  opacity: 0.6;
}

.cms-content p {
  margin-bottom: 20px;
  margin-top: 0;
}

@media (min-width: 1200px) {
  .cms-content p {
    margin-bottom: 1.04167vw;
  }
}

.cms-content h1,
.cms-content h2,
.cms-content h3,
.cms-content h4,
.cms-content h5,
.cms-content h6 {
  margin-bottom: 20px;
  margin-top: 0;
}

@media (min-width: 1200px) {
  .cms-content h1,
  .cms-content h2,
  .cms-content h3,
  .cms-content h4,
  .cms-content h5,
  .cms-content h6 {
    margin-bottom: 1.04167vw;
  }
}

.cms-content ul {
  list-style-type: none;
  padding-left: 0;
}

.cms-content ul li {
  margin-bottom: 15px;
  padding-left: 30px;
  position: relative;
}

@media (min-width: 1200px) {
  .cms-content ul li {
    margin-bottom: 0.78125vw;
    padding-left: 2.34375vw;
  }
}

.cms-content ul li:before {
  content: url(e1996fe7eb90ca2e1d5283afc91de781.svg);
  position: absolute;
  top: 2px;
  left: 0;
  height: 16px;
  width: 16px;
}

@media (min-width: 1200px) {
  .cms-content ul li:before {
    height: 20px;
    width: 20px;
  }
}

.cms-content--lightgray * {
  color: #bfbfbf;
}

.cms-content--lightgray strong {
  color: #fff;
}

.cms-content--lightgray strong a {
  color: #fff;
}

.cms-content--white {
  color: #fff;
}

.cms-content--white * {
  color: #fff;
}

.cms-content--normal {
  font-weight: 400;
}

.cms-content--small {
  color: #a3a3a3;
  font-weight: normal;
  font-size: 13px;
  line-height: 168.78%;
}

@media (min-width: 1200px) {
  .cms-content--small {
    font-size: 0.72917vw;
  }
}

.cms-content--small a {
  transition: all 0.3s ease-in-out;
  color: #a3a3a3;
  text-decoration: none;
}

.cms-content--small a:hover {
  color: #000;
}

@font-face {
  font-family: 'Montserrat';
  src: url(df71456f60d3710d82cc010a2b3ab36a.woff2) format("woff2"), url(30f0e9b85513d2c6a25d8a3206826488.woff) format("woff");
  font-weight: 800;
  font-style: italic;
}

@font-face {
  font-family: 'Montserrat';
  src: url(99b7ee26cc32cfbca99846f0e5312838.woff2) format("woff2"), url(08cb6a59c9fe8bcb7f34a51668df4933.woff) format("woff");
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: 'Montserrat';
  src: url(cec2d493e9b85b4b4e19838cd9579fd8.woff2) format("woff2"), url(68f02d7f0c95808a4553b17278e213cd.woff) format("woff");
  font-weight: 900;
  font-style: italic;
}

@font-face {
  font-family: 'Montserrat';
  src: url(71e915fef802011402d83882c9d25b5d.woff2) format("woff2"), url(dbcc6148a3b0ea92709d40334938dcb9.woff) format("woff");
  font-weight: 100;
  font-style: italic;
}

@font-face {
  font-family: 'Montserrat';
  src: url(43a841b1ec910ba2ecce5ee89515f462.woff2) format("woff2"), url(5eb647be12235c37d0b5ac801a8fde92.woff) format("woff");
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: 'Montserrat';
  src: url(8cdb23adc767148c1092bc5d4d62a2d5.woff2) format("woff2"), url(2f3578ba95a8f671cae5d3b494394ff9.woff) format("woff");
  font-weight: 200;
  font-style: italic;
}

@font-face {
  font-family: 'Montserrat';
  src: url(c74260cde1a16b48febba358704ac4b6.woff2) format("woff2"), url(f4103669740e1258103e7fee7bfafbc1.woff) format("woff");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Montserrat';
  src: url(69542103c5dd6cd037bea90bfe130577.woff2) format("woff2"), url(6bf17403f884285592f77a335b827914.woff) format("woff");
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: 'Montserrat';
  src: url(0b4a7a7914fe525bf6d705fe981cbf75.woff2) format("woff2"), url(2ed2c79ec59e88ff7121af75ef773af9.woff) format("woff");
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'Montserrat';
  src: url(40e31707c08e220773ea4b0bdf4f7919.woff2) format("woff2"), url(75ac2d463b1b7332a85e9bd0478567d2.woff) format("woff");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Montserrat';
  src: url(82168e0b17675a8bcb2930cf5c5a05da.woff2) format("woff2"), url(dcda6bebd29eb8720acad8f26a29c408.woff) format("woff");
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'Montserrat';
  src: url(8bd27fe9f42d7d0de8a3194c755fddd1.woff2) format("woff2"), url(7267159489b3753e6aba589a9f772446.woff) format("woff");
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: 'Montserrat';
  src: url(4193cb373574474bd262f0cada40c68e.woff2) format("woff2"), url(72aebf4516ddcbc7634c4640fb6f9a90.woff) format("woff");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Montserrat';
  src: url(5fec409400acb31e906e1b6c074292b8.woff2) format("woff2"), url(7b1d2334374bfee9ee4cf18d31385882.woff) format("woff");
  font-weight: 600;
  font-style: italic;
}

@font-face {
  font-family: 'Montserrat';
  src: url(6c8807219b0ecffdf96122b80df3e62c.woff2) format("woff2"), url(c0b8804ae85213c9194816da945db150.woff) format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Montserrat';
  src: url(1eaf59537a9c317acaa4b2c144cd89d6.woff2) format("woff2"), url(137a9f5f920bcccad63c115397dc1532.woff) format("woff");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Montserrat';
  src: url(bcc7a47bf7f66175dc05747d657e290f.woff2) format("woff2"), url(660f8f3371807ff41ee83b5e26f5b049.woff) format("woff");
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: 'Montserrat';
  src: url(ed5ced201e23ea869a95439f82ea01fd.woff2) format("woff2"), url(152b34f41b26230b38bba9f28b32fed6.woff) format("woff");
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: 'Poppins';
  src: url(2d9db5c9b67bce30464c0c4d0d840fef.woff2) format("woff2"), url(896fb06c7b3bead35bb2fbe6be7d8b54.woff) format("woff");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Poppins';
  src: url(c5e22317e35617a033d6ee8a2c50dc41.woff2) format("woff2"), url(8d3743ef9e185c44a5cb7c30d5920f7d.woff) format("woff");
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'Poppins';
  src: url(b854834365f937190b83aaa68f61c07b.woff2) format("woff2"), url(14da6265bd17503342b1772de7c320ef.woff) format("woff");
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: 'Poppins';
  src: url(3d5ed40daab66578492eb610375330b3.woff2) format("woff2"), url(8d92a75cb243049317c6629d930dcb69.woff) format("woff");
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'Poppins';
  src: url(d1d4a481ca2a9a5fb51cceb264395171.woff2) format("woff2"), url(108ec6dd05d83360811f1482ca4f308f.woff) format("woff");
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: 'Poppins';
  src: url(90563fd83de2f8a8b44433d58f888c47.woff2) format("woff2"), url(19da688be928e111c4555caef924be80.woff) format("woff");
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: 'Poppins';
  src: url(42a21f3f5b33aa4d346d0423428faacd.woff2) format("woff2"), url(164269256f0c2b8f3f3e7aa19a8edeb4.woff) format("woff");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Poppins';
  src: url(3588653249d857a419d7078921d647e3.woff2) format("woff2"), url(81873d33ae530a7a1beed1ec45d0fab7.woff) format("woff");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Poppins';
  src: url(43991518d0f678cde3b8e64aa00c036b.woff2) format("woff2"), url(cc5f98871935873dc78a8b677cf29ee5.woff) format("woff");
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: 'Poppins';
  src: url(e02e190248839fec59328523142ce88c.woff2) format("woff2"), url(1ed9a698de5b1b3328ff71950731a500.woff) format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Poppins';
  src: url(773ad3e5459612961aefc9530e8ffedf.woff2) format("woff2"), url(b8859cc1b7cdb9159341c0cb3b3bf6c6.woff) format("woff");
  font-weight: 200;
  font-style: italic;
}

@font-face {
  font-family: 'Poppins';
  src: url(ecbc7cb6ea1863ba4e00c3e514a89d53.woff2) format("woff2"), url(362d5991e980e0b2656cd75ed2a8ed04.woff) format("woff");
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: 'Poppins';
  src: url(7d9075e1565215aa939a2dcf538c10b6.woff2) format("woff2"), url(359845ea3d5672cb15d936bd597845af.woff) format("woff");
  font-weight: 100;
  font-style: italic;
}

@font-face {
  font-family: 'Poppins';
  src: url(38121a7b2db3f07d7ba139ea089c70cc.woff2) format("woff2"), url(0a514a668001f8e3f6fe9db42f4d8a90.woff) format("woff");
  font-weight: 800;
  font-style: italic;
}

@font-face {
  font-family: 'Poppins';
  src: url(a40007a5ff53186b4ef321e5f7e88ed2.woff2) format("woff2"), url(2787e7b82ef734055cdab20c7a1044f4.woff) format("woff");
  font-weight: 900;
  font-style: italic;
}

@font-face {
  font-family: 'Poppins';
  src: url(c304cf2572efc08f332d133c60177edb.woff2) format("woff2"), url(610b5f302ccd5af9290e5f820c5b639a.woff) format("woff");
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: 'Poppins';
  src: url(659f041be18f2568d8a9d6c6b6141003.woff2) format("woff2"), url(6dc518a37ee717f6c3b1aa66f2cdf1e9.woff) format("woff");
  font-weight: 600;
  font-style: italic;
}

@font-face {
  font-family: 'Poppins';
  src: url(3a7edf2f61eba7ba6b4d9e78da8ab57a.woff2) format("woff2"), url(ceaf8c4de9ee0f8530fddaa4a76a4df9.woff) format("woff");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Playfair Display';
  src: url(2cb3be0973ae29e02a7b9157063aebab.woff2) format("woff2"), url(524e1bc60994b3b64ac01438fbeb8cdf.woff) format("woff");
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: 'Playfair Display';
  src: url(0919a067d43e2609c644ab7d2f48adc3.woff2) format("woff2"), url(6dfe95e4326610e96e3f814391592db2.woff) format("woff");
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: 'Playfair Display';
  src: url(2ff7d650d1b69acdc402de1b700f74cf.woff2) format("woff2"), url(269f0d728f3dc4fbb12c3e06afb5bd44.woff) format("woff");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Playfair Display';
  src: url(b4705f3624c18a25e831c241615ebf5d.woff2) format("woff2"), url(d8ffb31a8410855515082e2a1f38f687.woff) format("woff");
  font-weight: 800;
  font-style: italic;
}

@font-face {
  font-family: 'Playfair Display';
  src: url(1afd21e76064d0edd7b351fd1cb8c619.woff2) format("woff2"), url(cbb6b4179fc691e9f57182054e059fc1.woff) format("woff");
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'Playfair Display';
  src: url(63d94c7093aea4695b014b0f53702056.woff2) format("woff2"), url(4675787a91b009be38e5a2dd8b6f8c3b.woff) format("woff");
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'Playfair Display';
  src: url(d55a43615659b772dc85955b1c7b1e28.woff2) format("woff2"), url(70c357ae66e6ceb8cf53ff4a27d33387.woff) format("woff");
  font-weight: 600;
  font-style: italic;
}

@font-face {
  font-family: 'Playfair Display';
  src: url(95da1f140b72e95d09eb4d5a8159845f.woff2) format("woff2"), url(624d87e42e34bf956dcde50b85e4f0cd.woff) format("woff");
  font-weight: 900;
  font-style: italic;
}

@font-face {
  font-family: 'Playfair Display';
  src: url(2dd8f19b24f27ce93329334efe204146.woff2) format("woff2"), url(85b816fafffafd6f9907082d5fe93fd9.woff) format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Playfair Display';
  src: url(02b456767ce92dba95dca98fcd090127.woff2) format("woff2"), url(6a4b181d315e6df7ef7ce6fe44e8d3d0.woff) format("woff");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Playfair Display';
  src: url(7ac3df0a209157982417630f81ddadc3.woff2) format("woff2"), url(4328b9d2ad9c2d7d384ccedef9047e9a.woff) format("woff");
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: 'Playfair Display';
  src: url(0a83765b0f386c6f188a0c21ffa77312.woff2) format("woff2"), url(0baebd5ff8c574ebb4b3475125cfa591.woff) format("woff");
  font-weight: 600;
  font-style: normal;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  font-family: 'Poppins', sans-serif;
  font-weight: normal;
  font-style: normal;
}

body.no-overflow {
  overflow: hidden;
}

a {
  text-decoration: none;
}

.container {
  width: 100%;
  padding: 0 30px;
  box-sizing: border-box;
  margin-left: auto;
  margin-right: auto;
  position: relative;
}

@media (min-width: 1200px) {
  .container {
    width: 72.91667vw;
  }
}

@media (min-width: 1600px) {
  .container {
    width: 62.5vw;
  }
}

@media (min-width: 1200px) {
  .container--full {
    width: 100%;
    padding: 0;
  }
}

@media (min-width: 1200px) {
  .container--narrow {
    max-width: 800px;
  }
}

.container--flex {
  display: flex;
  flex-wrap: wrap;
}

@media (min-width: 768px) {
  .container--wrap-md {
    flex-wrap: nowrap;
  }
}

@media (min-width: 960px) {
  .container--wrap-lg {
    flex-wrap: nowrap;
  }
}

.container--justify-space-between {
  justify-content: space-between;
}

.section-header {
  font-weight: 700;
  font-family: 'Playfair Display', serif;
  color: #fff;
  position: relative;
  display: inline-block;
  margin-bottom: 80px;
  font-size: 24px;
  padding-right: 30px;
}

@media (min-width: 768px) {
  .section-header {
    font-size: 40px;
  }
}

@media (min-width: 1200px) {
  .section-header {
    margin-bottom: 6.5625vw;
    font-size: 3.125vw;
    padding-right: 3.64583vw;
  }
}

.section-header:after {
  position: absolute;
  height: 1px;
  background: #8aa63a;
  width: 130%;
  bottom: -1.82292vw;
  left: 0;
  content: '';
}

@media (min-width: 1200px) {
  .section-header--longer {
    transform: translateX(-50%);
  }
}

.section-header--longer:after {
  right: 0;
  left: auto;
  width: 100vw;
}

.section-header--home {
  transform: translateX(0);
}

.section-header--dark {
  color: #000;
}

@media (min-width: 1200px) {
  .section-header--mb-105 {
    margin-bottom: 5.46875vw;
  }
}

@media (min-width: 1200px) {
  .section-header--mb-65 {
    margin-bottom: 3.38542vw;
  }
}

.footer {
  padding: 30px 0;
}

@media (min-width: 1200px) {
  .footer {
    padding: 3.125vw 0;
  }
}

.footer--home {
  flex: 0 0 100%;
  width: 100%;
  padding: 0 5.20833vw 0 5.20833vw;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  text-align: center;
}

@media (min-width: 960px) {
  .footer--home {
    flex-wrap: nowrap;
    text-align: left;
  }
}

.footer .container {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  text-align: center;
}

@media (min-width: 960px) {
  .footer .container {
    flex-wrap: nowrap;
    text-align: left;
  }
}

.footer__left {
  color: #9d9d9c;
  font-size: 12px;
  margin-bottom: 30px;
}

@media (min-width: 1200px) {
  .footer__left {
    font-size: 0.67708vw;
    margin-bottom: 0;
  }
}

.footer__left a {
  color: #8aa63a;
  transition: all 0.3s ease-in-out;
}

.footer__left a:hover {
  opacity: 0.6;
}

.footer__left,
.footer__right {
  text-align: center;
  flex: 0 0 100%;
  width: 100%;
}

@media (min-width: 960px) {
  .footer__left,
  .footer__right {
    flex: 0 0 auto;
    width: auto;
    text-align: initial;
  }
}

.footer__link {
  font-size: 11px;
  text-decoration: none;
  display: block;
  color: #3c3c3b;
  transition: all 0.3s ease-in-out;
}

@media (min-width: 960px) {
  .footer__link {
    margin-left: 47px;
    display: inline-block;
  }
}

.footer__link:hover {
  color: #8aa63a;
}

.footer__link--white {
  color: #fff;
}

.cookie-consent {
  font-family: 'Poppins', sans-serif;
  position: fixed;
  color: #4c4c4c;
  font-size: 9px;
  line-height: 120%;
  bottom: 0;
  background: #fff;
  width: 100%;
  left: 0;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.12);
  padding: 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 51;
}

@media (max-width: 767px) {
  .cookie-consent {
    justify-content: center;
    flex-direction: column;
    font-size: 9px;
  }
}

.cookie-consent__message {
  padding-right: 30px;
}

@media (max-width: 767px) {
  .cookie-consent__message {
    text-align: center;
    margin-bottom: 30px;
    padding-right: 0;
  }
}

.cookie-consent a {
  color: #000;
}

.cookie-consent button {
  background: #8aa63a;
  padding: 15px 30px;
  color: #fff;
  transition: all 0.3s ease-in-out;
  border: none;
  text-transform: uppercase;
  cursor: pointer;
  font-size: 10px;
}

.cookie-consent button:hover {
  background: #4c4c4c;
}

.page--homepage {
  padding: 100px 0 60px 0;
  overflow: hidden;
  background-image: url(5107cfed001559ba019763f5cea9895a.jpg);
  background-size: cover;
  min-height: 100vh;
}

@media (min-width: 960px) {
  .page--homepage {
    padding: 13.38542vw 0 5.20833vw 0;
  }
}

@media (min-width: 768px) {
  .page--homepage {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
  }
}

.homepage-intro {
  padding: 0 30px;
}

@media (min-width: 768px) {
  .homepage-intro {
    width: 70%;
  }
}

@media (min-width: 1200px) {
  .homepage-intro {
    width: 50%;
    padding: 0 5.20833vw 0 5.20833vw;
  }
}

@media (min-width: 1600px) {
  .homepage-intro {
    width: 44.27083vw;
  }
}

@media (min-width: 1200px) and (max-height: 599px) {
  .homepage-intro {
    width: 80%;
  }
}

@media (min-width: 1600px) and (max-height: 799px) {
  .homepage-intro {
    width: 70%;
  }
}

.homepage-intro__article {
  margin-bottom: 140px;
  display: block;
  position: relative;
}

.homepage-intro__article:after {
  position: absolute;
  height: 1px;
  background: #8aa63a;
  width: 80px;
  bottom: -70px;
  left: 0;
  content: '';
}

.homepage-intro__content {
  margin-bottom: 3.64583vw;
}

.homepage-intro__link {
  color: #fff;
}

.homepage-intro__btn {
  margin-bottom: 70px;
}

